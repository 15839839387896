import { throttle } from './common'

let queue = [],   // 滚动监听队列
  _window = $(window),
  _docment = $(document)

$(function () {
  let handler = throttle(e => {
    queue.filter(item => item).forEach(positionTo)
  }, 100, true)
  _window.scroll(handler).resize(handler)
})

export default {}

function positionTo({ target, threshold, fn }) {
  let winTop = _docment.scrollTop(),
    winH = _window.height()
  if (target.offset().top - (winH * threshold) <= winTop) {
    fn && fn()
  }
  return { target, threshold, fn }
}

/**
 * 滚动条到目标元素位置
 * @param {$} param.target 监听的元素 （jquery对象）
 * @param {*} param.threshold 偏移量 （元素进入到可视区域高度的百分比开始执行，例如0.7 目标元素滚动到可视区域的70%时触发）
 * @param {*} param.fn 触发回调
 */
export const arriveHere = ({ target, threshold = .7, fn } = {}) => {
  if (!target || !target[0]) return null
  queue.push(positionTo({
    target,
    threshold,
    fn,
  }))
  return function clear() {
    if (!queue.length) return
    delete queue[queue.length - 1]
  }
}