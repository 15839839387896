Date.prototype.Format = function(fmt) { //author: meizz
  var o = {
    'M+': this.getMonth() + 1,                 //月份
    'd+': this.getDate(),                    //日
    'h+': this.getHours(),                   //小时
    'm+': this.getMinutes(),                 //分
    's+': this.getSeconds(),                 //秒
    'q+': Math.floor((this.getMonth() + 3) / 3), //季度
    'S': this.getMilliseconds(),             //毫秒
  }
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length))
  for (var k in o)
    if (new RegExp('(' + k + ')').test(fmt))
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
  return fmt
}

/**
 * 是否是移动设备
 */
export function isMobile() {
  const ua = window.navigator.userAgent.toLowerCase()

  return /(iphone|android|winPhone|ipad)/.test(ua)
}

/**
 * 邮箱检测
 * @param {*} str
 */
export function isMatchEmail(str) {
  const reg = /[a-z0-9!#$%&'*+"=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+"=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
  const reg1 = /(.+)@10minutemail\.com|(.+)@dreggn\.com/
  return reg.test(str) && !reg1.test(str)
}

/**
 * 手机号检测
 * @param {*} str
 */
export function isMatchPhone(str) {
  const reg = /^1([38][0-9]|4[579]|5[0-3,5-9]|6[6]|7[0135678]|9[89])\d{8}$/
  return reg.test(str)
}

/**
 * 节流
 * @param {*} fn
 * @param {*} wait
 */
export function throttle(fn, wait = 200, immediately) {
  let prevTime = +new Date()
  if (immediately) {
    fn()
  }
  return (...args) => {
    let now = +new Date()
    if (now > prevTime + wait) {
      prevTime = now
      fn(...args)
    }
  }
}

/**
 * 防抖
 * @param {*} fn
 * @param {*} wait
 */
export function debounce(fn, wait = 200) {
  let timer = null
  return (...args) => {
    if (timer) clearTimeout(timer)
    timer = setTimeout(e => fn(...args), wait)
  }
}


/**
 * 获取浏览器的参数
 * @return {object}
 */
export function getParams() {
  const result = {}
  const str = location.search
  const params = new URLSearchParams(str)
  for (let k of params.keys()) {
    result[k] = params.get(k)
  }
  return result
}

/**
 * 判断hash是否相同
 * @param str
 * @param defaultHash
 */
export function isHash(str = '', defaultHash = 'all') {
  let hash = location.hash || defaultHash
  hash = hash.replace('#', '')
  return hash === str.trim()
}

export function getURLHash() {
  let hash = location.hash
  if (hash) {
    hash = hash.replace('#', '')
  }
  return hash
}

/** 简单克隆
 * @param obj
 * @returns {*}
 */
export function simpleClone(obj) {
  if (typeof obj === 'object') {
    return JSON.parse(JSON.stringify(obj))
  }
  else {
    return obj
  }
}

/**
 * 获取对象子属性
 * @param obj
 * @param str
 * @return {*}
 */
export function goc(obj, str) {
  if (!obj) return null

  if (typeof obj === 'object') obj = simpleClone(obj)
  if (typeof obj !== 'object' || !str) return obj
  if (str.indexOf('.') === -1) {
    if (obj[str] === undefined) {
      return null
    }
    else {
      return obj[str]
    }
  }

  const params = str.split('.')
  let data = obj
  for (let i = 0, j = params.length; i < j; i++) {
    let child = params[i]
    if (child !== '') {
      // console.log(obj, str)
      if (data[child] === undefined || data[child] === null) {
        data = null
        break
      }
      else {
        data = data[child]
      }
    }
  }
  return data
}

export function fromNow(time) {
  const YEAR = 60 * 60 * 24 * 365 * 1000
  const MONTH = 60 * 60 * 24 * 30 * 1000
  const DAY = 60 * 60 * 24 * 1000
  const HOUR = 60 * 60 * 1000
  const MIN = 60 * 1000

  const now = new Date().getTime()
  const timeData = new Date(time).getTime()
  const diff = now - timeData
  const year = diff > YEAR ? parseInt(diff / YEAR) : 0
  const month = (diff - year * YEAR) > MONTH ? parseInt((diff - year * YEAR) / MONTH) : 0
  const day = (diff - year * YEAR - month * MONTH) > DAY ? parseInt((diff - year * YEAR - month * MONTH) / DAY) : 0
  const hour = (diff - year * YEAR - month * MONTH - day * DAY) > HOUR ? parseInt((diff - year * YEAR - month * MONTH - day * DAY) / HOUR) : 0
  const min = (diff - year * YEAR - month * MONTH - day * DAY) > MIN ? parseInt((diff - year * YEAR - month * MONTH - day * DAY - hour * HOUR) / MIN) : 0

  // console.log(diff, time)

  let result = ''
  if (year > 0) {
    result += year + '年'
  }
  else if (month > 0) {
    result += month + '月'
  }
  else if (day > 0) {
    result += day + '天'
  }
  else if (hour > 0) {
    result += hour + '小时'
  }
  else if (min > 0) {
    result += min + '分钟'
  }

  if (result === '') {
    result = '刚刚'
  }
  else {
    result += '前'
  }

  // console.log(time, diff, now, result)

  return result
}

//
// fromNow('2019-07-02 19:30:22')
// fromNow('2019-07-02 15:33:22')
