import { isMobile, throttle, debounce, } from '../../utils/common'

// header 菜单部分 和 footer 返回底部
$(function () {
  let body = document.documentElement || document.body


  // 返回顶部部分
  {
    const backToTop = $('#backToTop'),
      [wrapperDom] = $('.wrapper')

    if (backToTop[0] && wrapperDom) {
      // 绑定点击事件
      backToTop.click(e => {
        $('html, body').stop().animate({
          scrollTop: 0,
        }, 'fast')
      })
      // 设置显示隐藏
      let isShow = false
      const setBackTopShow = () => {
        if (body.scrollTop >= body.clientHeight / 2) {
          if (!isShow) {
            isShow = true
            backToTop.fadeIn()
          }
        } else {
          isShow = false
          backToTop.fadeOut()
        }
      }
      // 设置返回顶部按钮的显示隐藏（首屏不显示）和左边距 (wrapper / 2 + 20)
      const setBtopState = function setleft(e) {
        let halfWrapWidth
        try {
          halfWrapWidth = wrapperDom.getClientRects()[0].width / 2
          if (halfWrapWidth * 2 + 100 > body.clientWidth) {   // 左边位置小于按钮宽度
            halfWrapWidth = halfWrapWidth - 50
          }
        } catch (e) {
          halfWrapWidth = body.clientWidth / 2 - 45
        }
        backToTop[0].style.marginLeft = halfWrapWidth + 5 + 'px'
        setBackTopShow()
        return setleft
      }()

      window.addEventListener('resize', throttle(setBtopState), { passive: false })
      window.addEventListener('scroll', throttle(setBackTopShow, 30), { passive: false })
    } else {
      backToTop.remove()
    }
  }
  // 移动端部分
  // if (!isMobile()) return

  let webmenus = $('#webmenus'),
    wapMenus = $('#wapMenus'),
    wapSubMenus = $('#wapSubMenus'),
    menuBtn = $('#menuBtn'),
    menuCover = $('#menuCover')


  // 移动端和web端公用菜单事件
  $('.menus-item-name.has-arrow,.footer-menus-item-name.has-arrow').click(function (e) {
    let _parent = $(this).parent()
    _parent.addClass('hover')
    setTimeout(e => {
      $(document).one('click', e => _parent.removeClass('hover'))
    })
  })


  // 移动端菜单事件
  wapMenus.on('click', function (e) {
    let _this = $(e.target)
    let info = _this.data('info')
    if (!info) return
    if (_this.hasClass('arrow-down')) {
      _this.removeClass('arrow-down').next().hide(0)
      return
    }
    _this.siblings('div').hide()
    _this.siblings('a').removeClass('arrow-down')
    _this.addClass('arrow-down').next().fadeIn(100)
  })

  menuBtn.on('click', function(){
    console.log('menu click')
  })
  menuBtn.click(toggleMenus(false))
  menuCover.click(toggleMenus(true))

  function toggleMenus(isCover) {
    return () => {
      menuCover.toggle()
      wapSubMenus.hide(0)
      wapMenus.show(0)
      if (isCover) {
        return wapMenus.removeClass('show')
      }
      wapMenus.toggleClass('show')
    }
  }
})
