
import { throttle, isMatchPhone, isMatchEmail } from 'src/utils/common'
import { arriveHere } from 'src/utils/scrollTo'
import Toast from 'src/utils/toast'

const API_ORDER = '/api/orders/'
const API_AUTH = '/api/organisation/'
const TOKEN_KEY = 'CLUB_USER'

var _czc = window._czc || function () { }

let DETAIL_STARTED_AT = Date.now()

$(function () {
  // 'zh-CN', 'en'
  let { curLang } = window
  const LANG = (function () {
    let cfg = {
      nowUse: {
        'zh-CN': '申请试用',
        'en': 'Apply',
      },
      iptMobileAndEmail: {
        'zh-CN': '请输入正确的手机号或邮箱',
        'en': 'Please input the correct mobile number or email',
      },
      hasVcode: {
        'zh-CN': '请输入验证码',
        'en': 'Please input Verify Code',
      },
      iptPwd: {
        'zh-CN': '请输入密码',
        'en': 'Please input a password',
      },
      logining: {
        'zh-CN': '登录中...',
        'en': 'Loading...',
      },
      loginSuccess: {
        'zh-CN': '登录成功！',
        'en': 'OK！',
      },
      iptMobile: {
        'zh-CN': '请输入正确的手机号',
        'en': 'Please input the correct mobile number',
      },
      iptEmail: {
        'zh-CN': '请输入正确的邮箱',
        'en': 'Please enter the correct email',
      },
      iptConactName: {
        'zh-CN': '请输入联系人',
        'en': 'Please enter the conact name',
      },
      iptAllInfo: {
        'zh-CN': '请输入完整的注册信息',
        'en': 'Please enter the complete registration information',
      },
      reging: {
        'zh-CN': '申请中...',
        'en': 'Loading...',
      },
      regSuccess: {
        'zh-CN': '注册成功！',
        'en': 'OK！',
      },
      vcodesendSuccess: {
        'zh-CN': '发送成功！',
        'en': 'OK！',
      },
      mayuseIng: {
        'zh-CN': '申请中...',
        'en': 'Loading...',
      },
      mayUseServer: {
        'zh-CN': '申请服务',
        'en': 'Apply for Services',
      },
      logoutSuccess: {
        'zh-CN': '退出成功',
        'en': 'OK！',
      },
      pleaseLogin: {
        'zh-CN': '请先登录~',
        'en': 'Please sign in first~',
      },
      fetchErr: {
        'zh-CN': '请求出错',
        'en': 'Error',
      },
      welcome: {
        'zh-CN': '欢迎您，',
        'en': 'Welcome,',
      },
      trysuccess: {
        'zh-CN': '我们已收到您的申请，将稍后和您联系',
        'en': 'We have received your application and will contact you later',
      },
      submiting: {
        'zh-CN': '提交中...',
        'en': 'submiting...',
      },
    }
    for (let k in cfg) {
      cfg[k] = cfg[k][curLang]
    }
    return cfg
  })()

  // ========== 效果 ==========
  // 登录注册切换
  let isLoginShow = false,
    loginbox = $('#loginbox'),
    regbox = $('#regbox')

  const loginRegChange = e => {
    isLoginShow = location.hash === '#reg'
    let tar = !isLoginShow ? loginbox : regbox,
      other = isLoginShow ? loginbox : regbox
    tar.removeClass('show').hide(0)
    other.show(0).addClass('show')
  }
  const allserver = $('#allserver')

  arriveHere({
    target: allserver,
    threshold: .8,
    fn: e => {
      if (!allserver.hasClass('show')) {
        allserver.addClass('show')
      }
    },
  })

  // ========= 事件 ========

  // 加入
  const $regbtnDom = $('#regBtn')
  $regbtnDom.click(e => {
    if ($regbtnDom.loading) return
    let errmsg = null
      ;['#regContactName', '#regContactTel', '#verifyCode'].forEach(e => {
        let val = $(e).val()
        if (errmsg) return
        if (e === '#regContactName' && !val) {
          return errmsg = LANG.iptConactName
        }
        if (e === '#regContactTel' && !isMatchPhone(val)) {
          return errmsg = (LANG.iptMobile)
        }
        if (e === '#verifyCode' && !val) {
          return errmsg = LANG.hasVcode
        }
      })
    if (errmsg) {
      return Toast({
        title: errmsg,
        icon: 'error',
      })
    }
    let _this = $(this)
    if (_this.text() === (LANG.mayuseIng)) return
    _this.text((LANG.mayuseIng))
    let param = {
      'contactName': $('#regContactName').val(),
      'contactTel': $('#regContactTel').val(),
      'verifyCode': $('#verifyCode').val(),
    }
    let hideToast = Toast({
      title: LANG.submiting,
      icon: 'loading',
      duration: -1,
    })
    $regbtnDom.loading = true
    request({
      type: 'POST',
      url: API_AUTH + 'company/register',
      data: param,
      mustToken: false,
      success: e => {
        $regbtnDom.loading = false
        // $('#backLogin').click()
        hideToast.remove()
        Toast({
          title: LANG.trysuccess,
        })
        setTimeout(() => {
          $('#regContactName').val('')
          $('#regContactTel').val('')
          $('#verifyCode').val('')
        }, 1500)
      },
      error: e => {
        hideToast.remove()
        $regbtnDom.loading = false
      },
    })
  })

  // 发送验证码
  let sendvcodeBtn = $('#sendvcodeBtn')
  let vcodeTime = 60
  sendvcodeBtn.click(function () {
    const regContactTel = $('#regContactTel').val().trim()
    if (!isMatchPhone(regContactTel)) return Toast({
      title: LANG.iptMobile,
      icon: 'error',
    })
    if (sendvcodeBtn.sending) return
    sendVcodeReq(regContactTel)
  })

  function sendVcodeReq(regContactTel) {
    sendvcodeBtn.sending = true
    sendvcodeBtn.css('color', '#ccc')
    const beforetext = sendvcodeBtn.text()
    let timer = null
    const fn = () => {
      sendvcodeBtn.text('(' + vcodeTime + ')')
      vcodeTime -= 1
      if (vcodeTime < 0) {
        resetSendVcodeBtn()
      }
    }

    function resetSendVcodeBtn(isall) {
      sendvcodeBtn.text(beforetext)
      vcodeTime = 60
      clearInterval(timer)
      sendvcodeBtn.css('color', '#fff')
      sendvcodeBtn.sending = false
    }

    request({
      type: 'POST',
      url: API_AUTH + 'company/otp/send?mobile=' + regContactTel,
      mustToken: false,
      success: e => {
        fn()
        timer = setInterval(fn, 1000)
        Toast({
          title: LANG.vcodesendSuccess,
        })
      },
      error: e => {
        resetSendVcodeBtn()
      },
    })
    return
  }

  /**
   * 查看价格
   */
  $('#price').click(e => {
    // priceDetail.show()
    location = '#price'
  })
  let priceDetail = $('#priceDetail')
  $('#priceDetailCover,#priceDetailOk').click(e => {
    // priceDetail.hide(0)
    location = '#proboxTarget'
  })
  const showPrice = () => {
    loginRegChange()
    if (location.hash === '#price') {
      priceDetail.show()
    }
    else {
      priceDetail.hide(0)
    }
  }
  showPrice()
  window.addEventListener('hashchange', showPrice)


  /**
   * 请求
   * @param {*} fn
   */
  function request({ url, type = 'GET', data, success, mustToken = true, error }) {
    type = type.toLocaleUpperCase()
    if (mustToken && !localStorage[TOKEN_KEY]) {
      $('#productDetailClose').click()
      $(document).scrollTop(0)
      Toast({
        title: LANG.pleaseLogin,
        icon: 'loading',
        duration: 1000,
      })
      error && error()
      return
    }

    $.ajax({
      url: url,
      data: data ? JSON.stringify(data) : null,
      type,
      contentType: 'application/json',
      dataType: 'json',
      success: data => {
        if (data.code == 200) {
          return success(data)
        }
        Toast({
          title: data.msg || LANG.fetchErr,
          duration: 1300,
          icon: 'error',
        })
        error && error()
      },
      error(data) {
        Toast({
          title: LANG.fetchErr,
          duration: 1300,
          icon: 'error',
        })
        error && error()
      },
    })
  }
})