/**
 * 弹窗
 * @param {String} params.icon toast样式
 * @param {String} params.title 提示文字
 * @param {Boolean} params.duration 持续时长 （-1则永久显示）
 * @return {any} duration为-1 返回 hade方法，否则返回空
 */
export default function Toast(params = {}) {
  let { icon = 'success', title, duration = 1500 } = params
  let fontColor = '#333',
    iconName = './libs/success.png'
  if (icon === 'error') {
    // fontColor = '#d81e06'
    iconName = './libs/error.png'
  }
  if (icon === 'loading') {
    iconName = './libs/loading.svg'
  }
  let toast = $(`
    <div style='position: fixed;top: -200px;left: 50%;background: #fff;transform: translateX(-50%);padding: 7px 10px;display: flex;align-items: center;font-size: 12px;border-radius: 3px;color: ${fontColor};box-shadow: #585757 2px 2px 10px;border: 1px solid #eae6e6;z-index: 99999999;'>
      <img src='${iconName}' style='height: 25px; margin-right: 10px;' />
      <span>${title}</span>
    </div>
  `)
  toast
    .appendTo($('body'))
    .animate({
      top: '30px'
    }, 'fast')
  if (duration !== -1) {
    setTimeout(e => {
      toast.remove()
    }, duration)
    return
  }

  return toast
}